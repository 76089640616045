/* eslint-disable @typescript-eslint/no-unsafe-return */

// General
export const ImageRailgunLogo = () =>
  require('../../assets/img/general/railgun-logo.png');
export const ImageSwirl = () => require('../../assets/img/general/swirl.png');

// Tokens
// NOTE: addedTokens being persisted in storage may rely on these.
// If we ever remove any of these images, we should test that any wallets that store and rely on one of these images fails gracefully to the placeholder image (it should)
export const ImageTokenEth = () => require('../../assets/img/tokens/ETH.png');
export const ImageTokenUsdc = () => require('../../assets/img/tokens/USDC.png');
export const ImageTokenUSDCe = () =>
  require('../../assets/img/tokens/USDCe.png');
export const ImageTokenRail = () => require('../../assets/img/tokens/RAIL.png');
export const ImageTokenBnb = () => require('../../assets/img/tokens/BNB.png');
export const ImageTokenMatic = () =>
  require('../../assets/img/tokens/MATIC.png');
export const ImageTokenWeth = () => require('../../assets/img/tokens/WETH.png');
export const ImageTokenWbnb = () => require('../../assets/img/tokens/WBNB.png');
export const ImageTokenWmatic = () =>
  require('../../assets/img/tokens/WMATIC.png');
export const ImageTokenArb = () => require('../../assets/img/tokens/ARB.png');
export const ImageTokenWbtc = () => require('../../assets/img/tokens/WBTC.png');
export const ImageTokenDai = () => require('../../assets/img/tokens/DAI.png');
export const ImageTokenBusd = () => require('../../assets/img/tokens/BUSD.png');
export const ImageTokenUsdt = () => require('../../assets/img/tokens/USDT.png');
export const ImageTokenSepolia = () =>
  require('../../assets/img/tokens/SEPOLIA.png');
export const ImageTokenHardhat = () =>
  require('../../assets/img/tokens/HARDHAT.png');
export const ImageTokenPlaceholder = () =>
  require('../../assets/img/tokens/placeholder.png');

// Recipe Tokens
export const ImageRecipeTokenBeefy = () =>
  require('../../assets/img/recipes/beefy.png');
export const ImageRecipeTokenUniswap = () =>
  require('../../assets/img/recipes/uniswap.png');
export const ImageRecipeTokenSushiswap = () =>
  require('../../assets/img/recipes/sushiswap.png');
export const ImageRecipeTokenPancakeSwap = () =>
  require('../../assets/img/recipes/pancakeswap.png');
export const ImageRecipeTokenQuickswap = () =>
  require('../../assets/img/recipes/quickswap.png');

// Chains
export const ImageChainEthereum = () =>
  require('../../assets/img/chains/ethereum.png');
export const ImageChainBinance = () =>
  require('../../assets/img/chains/binance-smart-chain.png');
export const ImageChainPolygon = () =>
  require('../../assets/img/chains/polygon.png');
export const ImageChainArbitrum = () =>
  require('../../assets/img/chains/arbitrum.png');
export const ImageChainSepolia = () =>
  require('../../assets/img/chains/sepolia.png');
export const ImageChainHardhat = () =>
  require('../../assets/img/chains/hardhat.png');

// Icons
export const IconShielded = () =>
  require('../../assets/img/icons/shielded.png');
export const IconPublic = () => require('../../assets/img/icons/public.png');
export const IconNo = () => require('../../assets/img/icons/no.png');
